import React, { useEffect, useState } from "react";
import { NumericFormat } from "../../../components/NumericFormat";
import { Grid, TextField, MenuItem, makeStyles } from "@material-ui/core";
import { Button, Modal, ButtonToolbar } from "react-bootstrap";

import axios from "../../../services/Api";
import { SellValueCalculator } from "../../../components/SellValueCalculator";
import { StockLocations } from "../../../types/StockLocations";
import { useSelector } from "react-redux";
import ApiResourceSelect from "../../../components/ApiResourceSelect";
import { Customer } from "../../../types/Customer";
import CustomerService from "../../../services/CustomerService";
import BrandService from "../../../services/BrandService";
import { CategoryProduct } from "../../../hooks/products";
import CategoryProductService from "../../../services/CategoryProductService";
import SubCategoryProductService from "../../../services/SubCategoryProductService";
import StockLocationService from "../../../services/StockLocationService";
import { ApiResourceSelectAddButton } from "../../../components/ApiResourceSelectAddButton";
import { NewCustomerModal } from "../../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../../hooks/newCustomerForm";
import { NewStockLocationModal } from "../../../components/Parameters/NewStockLocationModal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type Variation = {
  variationName: string;
};

type Grid = {
  id: number;
  name: string;
  variation: Variation[];
};

type VariationProductData = {
  variation: any;
};

type Props = {
  isKit: string;
  urlId: string;
  buyValue: string;
  ipiMoney: string;
  ipiPercent: string;
  freightMoney: string;
  hasVariation: string;
  calcPrice: () => void;
  aditionalCost: string;
  freightPercent: string;
  showModalPrice: boolean;
  brandProduct: never[];
  categoryProduct: never[];
  profitMarginMoney: string;
  creditIcmsstMoney: string;
  profitMarginPercent: string;
  creditIcmsstPercent: string;
  subCategoryProduct: never[];
  selectedCategoryProductUuid: string;
  variationProduct: VariationProductData;
  setBuyValue: React.Dispatch<React.SetStateAction<string>>;
  setIpiMoney: React.Dispatch<React.SetStateAction<string>>;
  setIpiPercent: React.Dispatch<React.SetStateAction<string>>;
  setHasVariation: React.Dispatch<React.SetStateAction<string>>;
  setFreightMoney: React.Dispatch<React.SetStateAction<string>>;
  setAditionalCost: React.Dispatch<React.SetStateAction<string>>;
  setFreightPercent: React.Dispatch<React.SetStateAction<string>>;
  setShowModalPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setBrandProduct: React.Dispatch<React.SetStateAction<never[]>>;
  setCategoryProduct: React.Dispatch<React.SetStateAction<never[]>>;
  setProfitMarginMoney: React.Dispatch<React.SetStateAction<string>>;
  setCreditIcmsstMoney: React.Dispatch<React.SetStateAction<string>>;
  setProfitMarginPercent: React.Dispatch<React.SetStateAction<string>>;
  setCreditIcmsstPercent: React.Dispatch<React.SetStateAction<string>>;
  setSubCategoryProduct: React.Dispatch<React.SetStateAction<never[]>>;
  setSelectedCategoryProductUuid: React.Dispatch<React.SetStateAction<string>>;
  stockInvalid: boolean;
  setStockInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockMinInvalid: boolean;
  setStockMinInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockMaxInvalid: boolean;
  setStockMaxInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  stockInitialInvalid: boolean;
  setStockInitialInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  typeInvalid: boolean;
  setTypeInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  nameInvalid: boolean;
  setNameInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  unitInvalid: boolean;
  setUnitInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  saleValueInvalid: boolean;
  setSaleValueInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  productByGtinEanCodeInvalid: boolean;
  setProductByGtinEanCodeInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  gridSelected: Grid[];
  errors: any;
  name: string;
  code: string;
  unit: string;
  type: string;
  note: string;
  brand: string;
  category: string;
  icmsRate: string;
  stockMin: string;
  stockMax: string;
  provider: string;
  saleValue: string;
  costValue: string;
  situation: string;
  icmsStRate: string;
  codeGtinEan: string;
  productSize: string;
  subCategory: string;
  stockInitial: string;
  stockLocation: number;
  typeClassification: string;
  ordersNumber: string;
  setNcm(value: any): void;
  setName(value: any): void;
  setCode(value: any): void;
  setUnit(value: any): void;
  setType(value: any): void;
  setNote(value: any): void;
  setBrand(value: any): void;
  setState(value: any): void;
  setIsKit(value: any): void;
  setIpiRate(value: any): void;
  setPisRate(value: any): void;
  setFederal(value: any): void;
  setCstRate(value: any): void;
  setMvaRate(value: any): void;
  setCategory(value: any): void;
  setIcmsRate(value: any): void;
  setCestCode(value: any): void;
  setStockMin(value: any): void;
  setStockMax(value: any): void;
  setProvider(value: any): void;
  setSaleValue(value: any): void;
  setCostValue(value: any): void;
  setSituation(value: any): void;
  setIcmsStRate(value: any): void;
  setCofinsRate(value: any): void;
  setCodeGtinEan(value: any): void;
  setProductSize(value: any): void;
  setGrossWeight(value: any): void;
  setSubCategory(value: any): void;
  setTaxableUnit(value: any): void;
  setProductsKit(value: any): void;
  setGridSelected(value: any): void;
  setLiquidColumn(value: any): void;
  setStockInitial(value: any): void;
  setDeferralRate(value: any): void;
  setStockLocation(value: any): void;
  setProductOrigin(value: any): void;
  setOrdersNumber(value: any): void;
  setTaxBenefitCode(value: any): void;
  setMvaAdjustedRate(value: any): void;
  setVariationProduct(value: any): void;
  setTypeClassification(value: any): void;
  sendStockNotification: string;
  setSendStockNotification: React.Dispatch<React.SetStateAction<any>>;
};

export function ProductDetails({
  isKit,
  urlId,
  unit,
  type,
  note,
  brand,
  errors,
  category,
  stockMin,
  stockMax,
  provider,
  saleValue,
  costValue,
  situation,
  codeGtinEan,
  productSize,
  subCategory,
  stockInitial,
  stockLocation,
  typeClassification,
  setNcm,
  setName,
  setCode,
  setUnit,
  setType,
  setNote,
  setBrand,
  setState,
  setIsKit,
  setIpiRate,
  setPisRate,
  setFederal,
  setCstRate,
  setMvaRate,
  setCategory,
  setIcmsRate,
  setStockMin,
  setStockMax,
  setProvider,
  setSaleValue,
  setCostValue,
  setSituation,
  setCodeGtinEan,
  hasVariation,
  setIcmsStRate,
  setCofinsRate,
  showModalPrice,
  setProductSize,
  setGrossWeight,
  setSubCategory,
  setTaxableUnit,
  setProductsKit,
  setLiquidColumn,
  setStockInitial,
  setDeferralRate,
  setHasVariation,
  setStockLocation,
  setProductOrigin,
  ordersNumber,
  setOrdersNumber,
  setTaxBenefitCode,
  setShowModalPrice,
  setMvaAdjustedRate,
  setTypeClassification,
  selectedCategoryProductUuid,
  setSelectedCategoryProductUuid,
  stockInvalid,
  stockMinInvalid,
  setStockMinInvalid,
  stockMaxInvalid,
  setStockMaxInvalid,
  stockInitialInvalid,
  setStockInitialInvalid,
  unitInvalid,
  setUnitInvalid,
  saleValueInvalid,
  setSaleValueInvalid,
  productByGtinEanCodeInvalid,
  setProductByGtinEanCodeInvalid,
  sendStockNotification,
  setSendStockNotification,
}: Props) {
  const { user } = useSelector((state: any) => state.auth);

  const [
    newStockLocationDefaultData,
    setNewStockLocationDefaultData,
  ] = useState("");
  const [showModalNewStockLocation, setShowModalNewStockLocation] = useState(
    false
  );
  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  const classes = useStyles();

  // const [selectedType, setSelectedType] = useState();
  const [saleValueApply, setSaleValueApply] = useState("");

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setProvider(String(createdCustomer.id));
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText, typeRegister: "supplier" });
    setShowModalNewCustomer(true);
  };

  const handleClickAddStockLocation = (typedText: string) => {
    setNewStockLocationDefaultData(typedText);
    setShowModalNewStockLocation(true);
  };

  const handleCreateStockLocation = (createdStockLocation: StockLocations) => {
    setStockLocation(String(createdStockLocation.id));
  };

  function applySellValue(value: number) {
    setSaleValueApply(String(value).replace(".", ","));
    setSaleValueInvalid(false);
    setSaleValue(String(value).replace(".", ","));
  }

  async function registerSelectedCategoryProductUuid(idCategory: number) {
    try {
      const category = await axios.get(`/categoryproduct/${idCategory}`);

      setSelectedCategoryProductUuid(category.data.uuidCategory);

      setSubCategory("");
    } catch (error) {
      console.log(error);
      setSubCategory("");
    }
  }

  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(`/products/${urlId}`);

      setNcm(data.ncm);
      setName(data.name);
      setCode(data.code);
      setUnit(data.unit);
      setType(data.type);
      setNote(data.note);
      setBrand(data.brand);
      setState(data.state);
      setIsKit(data.isKit);
      setIpiRate(data.ipiRate);
      setPisRate(data.pisRate);
      setFederal(data.federal);
      setCstRate(data.cstRate);
      setMvaRate(data.mvaRate);
      setCategory(data.category ?? "");
      setIcmsRate(data.icmsRate);
      // setCestCode(data.cestCode);
      setStockMin(data.stockMin);
      setStockMax(data.stockMax);
      setProvider(data.provider);
      setSaleValue(data.saleValue);
      setCostValue(data.costValue);
      setSituation(data.situation);
      setIcmsStRate(data.icmsStRate);
      setCofinsRate(data.cofinsRate);
      setCodeGtinEan(data.codeGtinEan);
      setProductSize(data.productSize);
      setGrossWeight(data.grossWeight);
      setSubCategory(data.subCategory);
      setTaxableUnit(data.taxableUnit);
      setLiquidColumn(data.liquidColumn);
      setHasVariation(data.hasVariation);
      setStockInitial(data.stockInitial);
      setDeferralRate(data.deferralRate);
      setStockLocation(data.stockLocation);
      setProductOrigin(data.productOrigin);
      setOrdersNumber(data.ordersNumber);
      setTaxBenefitCode(data.taxBenefitCode);
      setMvaAdjustedRate(data.mvaAdjustedRate);
      setTypeClassification(data.typeClassification);
      setProductsKit({ products: JSON.parse(data.kit) });

      if (data.category) {
        const selectedCategoryProduct = await axios.get(
          `/categoryproduct/${data.category}`
        );

        setSelectedCategoryProductUuid(
          selectedCategoryProduct.data.uuidCategory
        );
      } else {
        setSelectedCategoryProductUuid("");
      }
    }

    getData();
  }, []);

  return (
    <div className="newProductWrapper">
      <Modal
        show={showModalError}
        onHide={() => setShowModalError(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalError(!showModalError)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
        allowedType="supplier"
      />

      <NewStockLocationModal
        showModal={showModalNewStockLocation}
        setShowModal={setShowModalNewStockLocation}
        onCreateStockLocation={handleCreateStockLocation}
        defaultData={newStockLocationDefaultData}
      />

      <Grid container spacing={3}>
        <Grid item lg={2}>
          <TextField
            id="outlined-select-currency"
            select
            label="Unidade"
            size="small"
            required
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            //{...register('unit', { required: 'Campo Obrigatório!' })}
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
              setUnitInvalid(e.target.value ? false : true);
            }}
            error={unitInvalid}
            disabled={user.isAccountant == "y" ? true : false}
          >
            <MenuItem key="pc" value="Pç">
              Pç
            </MenuItem>
            <MenuItem key="kg" value="Kg">
              Kg
            </MenuItem>
            <MenuItem key="un" value="Un">
              Un
            </MenuItem>
          </TextField>
          {errors.unit && errors.unit.type === "required" && (
            <span className="text-danger pl-3">{errors.unit.message}</span>
          )}
        </Grid>

        <Grid item lg={3}>
          <ApiResourceSelect
            label="Marca"
            noOptionsText="Nenhuma marca encontrada"
            getOptionLabel={(option: any) => option.nameBrand}
            value={brand}
            onSelect={(option) => setBrand(String(option?.id ?? ""))}
            apiSearchHandler={(typedText) =>
              BrandService.getBrandsFiltered({ name: typedText })
            }
            getSelectedOption={(loadedOptions) => {
              if (!brand) return null;
              return (
                loadedOptions.find((option) => option.id === Number(brand)) ??
                BrandService.getBrandById(brand)
              );
            }}
            disabled={user.isAccountant == "y" ? true : false}
          />
          {errors.brand && errors.brand.type === "required" && (
            <span className="text-danger pl-3">{errors.brand.message}</span>
          )}
        </Grid>

        <Grid item lg={3}>
          <TextField
            label="Código GTIN/EAN"
            size="small"
            margin="normal"
            variant="outlined"
            value={codeGtinEan}
            onChange={(e) => {
              setCodeGtinEan(e.target.value);
              setProductByGtinEanCodeInvalid(e.target.value ? false : true);
            }}
            error={productByGtinEanCodeInvalid}
            // {...register('codeGtinEan', {
            // pattern: {
            //     value: /[0-9]/,
            //     message: 'Campo somente números!',
            // },
            // })}
            disabled={user.isAccountant == "y" ? true : false}
          />
          {errors.codeGtinEan && errors.codeGtinEan.type === "pattern" && (
            <span className="text-danger pl-3">
              {errors.codeGtinEan.message}
            </span>
          )}
        </Grid>

        <Grid item lg={4}>
          <TextField
            id="outlined-select-currency"
            select
            size="small"
            label="Tipo de classificação"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            //{...register('typeClassification')}
            value={typeClassification}
            onChange={(e) => setTypeClassification(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          >
            <MenuItem key="" value="">
              Selecione
            </MenuItem>

            <MenuItem key="0" value="00 - Mercadoria para Revenda">
              00 - Mercadoria para Revenda
            </MenuItem>

            <MenuItem key="1" value="01 - Matéria-Prima">
              01 - Matéria-Prima
            </MenuItem>

            <MenuItem key="2" value="02 - Embalagem">
              02 - Embalagem
            </MenuItem>

            <MenuItem key="3" value="03 - Produto em Processo">
              03 - Produto em Processo
            </MenuItem>

            <MenuItem key="4" value="04 - Produto Acabado">
              04 - Produto Acabado
            </MenuItem>

            <MenuItem key="5" value="05 - Subproduto">
              05 - Subproduto
            </MenuItem>

            <MenuItem key="6" value="06 - Produto Intermediário">
              06 - Produto Intermediário
            </MenuItem>

            <MenuItem key="7" value="07 - Material de Uso e Consumo">
              07 - Material de Uso e Consumo
            </MenuItem>

            <MenuItem key="8" value="08 - Ativo Imobilizado">
              08 - Ativo Imobilizado
            </MenuItem>

            <MenuItem key="9" value="09 - Serviços">
              09 - Serviços
            </MenuItem>

            <MenuItem key="10" value="10 - Outros insumos">
              10 - Outros insumos
            </MenuItem>

            <MenuItem key="99" value="99 - Outras">
              99 - Outras
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {hasVariation === "y" || isKit === "y"
        ? ""
        : type === "product" && (
            <Grid container spacing={3}>
              <Grid item lg={3}>
                <ApiResourceSelect
                  style={{ width: "100%" }}
                  label="Localização no estoque"
                  getOptionLabel={(option: StockLocations) => option.name}
                  value={stockLocation}
                  onSelect={(option) => setStockLocation(option?.id ?? 0)}
                  apiSearchHandler={(typedText) =>
                    StockLocationService.getStockLocationsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!stockLocation) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(stockLocation)
                      ) ??
                      StockLocationService.getStockLocationById(stockLocation)
                    );
                  }}
                  hasError={stockInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                  renderAddButton={(typedText) => (
                    <ApiResourceSelectAddButton
                      label="Adicionar um Local de Estoque"
                      onClick={() => handleClickAddStockLocation(typedText)}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3}>
                <TextField
                  size="small"
                  label="Estoque mínimo"
                  required
                  margin="normal"
                  variant="outlined"
                  type="number"
                  value={stockMin}
                  onChange={(e) => {
                    setStockMin(e.target.value);
                    setStockMinInvalid(e.target.value ? false : true);
                  }}
                  error={stockMinInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                />
              </Grid>

              <Grid item lg={3}>
                <TextField
                  size="small"
                  label="Estoque máximo"
                  required
                  margin="normal"
                  variant="outlined"
                  type="number"
                  value={stockMax}
                  onChange={(e) => {
                    setStockMax(e.target.value);
                    setStockMaxInvalid(e.target.value ? false : true);
                  }}
                  error={stockMaxInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                />
              </Grid>

              <Grid item lg={3}>
                <TextField
                  size="small"
                  label="Estoque inicial"
                  required
                  margin="normal"
                  variant="outlined"
                  type="number"
                  value={stockInitial}
                  onChange={(e) => {
                    setStockInitial(e.target.value);
                    setStockInitialInvalid(e.target.value ? false : true);
                  }}
                  error={stockInitialInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                />
              </Grid>
            </Grid>
          )}

      <Grid container spacing={3}>
        <Grid item lg={3}>
          <NumericFormat
            label="Valor de venda"
            required
            className={classes.error}
            startAdornment="R$"
            value={saleValueApply ? saleValueApply : saleValue}
            onChange={(e) => {
              setSaleValue(e.target.value);
              setSaleValueInvalid(e.target.value ? false : true);
              setSaleValueApply(e.target.value);
            }}
            error={saleValueInvalid}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>

        <Grid item lg={3}>
          <NumericFormat
            label="Valor de custo"
            startAdornment="R$"
            value={costValue}
            onChange={(e) => setCostValue(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>

        {user.isAccountant == "n" ? (
          <Grid item lg={3} className="d-flex align-items-center">
            <ButtonToolbar>
              <Button
                variant="primary"
                onClick={() => setShowModalPrice(!showModalPrice)}
              >
                Calcular valor de venda
                <i className="flaticon-coins p-1"></i>
              </Button>

              <SellValueCalculator
                isModalOpen={showModalPrice}
                setIsModalOpen={setShowModalPrice}
                applySellValue={applySellValue}
              />
            </ButtonToolbar>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={3}>
          <NumericFormat
            label="Tamanho do produto"
            startAdornment="cm"
            value={productSize}
            onChange={(e) => setProductSize(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Número da ordem"
            value={ordersNumber}
            onChange={(e) => setOrdersNumber(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            size="small"
            id="outlined-select-currency"
            select
            label="Situação"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            value={situation}
            onChange={(e) => setSituation(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          >
            <MenuItem key="active" value="active">
              Ativo
            </MenuItem>

            <MenuItem key="inactive" value="inactive">
              Inativo
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item md={2}>
          <TextField
            size="small"
            id="outlined-select-currency"
            select
            label="Notifica Estoque"
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="outlined"
            onChange={(e) => setSendStockNotification(e.target.value)}
            value={sendStockNotification}
          >
            <MenuItem key="active" value="y">
              Sim
            </MenuItem>

            <MenuItem key="inactive" value="n">
              Não
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={2}>
          <ApiResourceSelect
            label="Fornecedor"
            getOptionLabel={(option: Customer) =>
              `${option.id} - ${option.name}`
            }
            value={provider}
            onSelect={(option) => setProvider(option ? String(option.id) : "")}
            apiSearchHandler={(typedText) =>
              CustomerService.getCustomersFiltered({
                name: typedText,
                nameWithId: true,
                typeRegister: "supplier",
              })
            }
            getSelectedOption={(loadedOptions) => {
              if (!provider) return null;
              return (
                loadedOptions.find(
                  (option) => option.id === Number(provider)
                ) ?? CustomerService.getCustomerById(provider)
              );
            }}
            renderAddButton={(typedText) => (
              <ApiResourceSelectAddButton
                label="Adicionar Fornecedor"
                onClick={() => handleClickAddCustomer(typedText)}
              />
            )}
          />
          {/* <TextField
                        size="small"
                        id='outlined-select-currency'
                        select
                        label='Fornecedor'                        
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={provider}
                        disabled={user.isAccountant == "y" ? true : false}
                    >
                        <MenuItem value=''>
                            Selecione
                        </MenuItem>
                        {
                            suppliers.map((value, index) => {
                                return (
                                    <MenuItem key={index} value={value.id} onClick={() => setProvider(String(value.id))}>
                                        {value.name}
                                    </MenuItem>
                                )
                            })
                        }                        
                    </TextField> */}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={12}>
          <TextField
            size="small"
            label="Observações"
            multiline
            rows="2"
            margin="normal"
            variant="outlined"
            //{...register('note')}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid
          item
          lg={5}
          className="mb-10 d-flex justify-content-between align-items-center"
        >
          <ApiResourceSelect
            label="Categoria"
            style={{ width: "100%" }}
            noOptionsText="Nenhuma categoria encontrada"
            getOptionLabel={(option: CategoryProduct) => option.nameCategory}
            value={category}
            onSelect={(option) => {
              setCategory(String(option?.id ?? ""));
              registerSelectedCategoryProductUuid(option?.id ?? 0);
            }}
            apiSearchHandler={(typedText) =>
              CategoryProductService.getCategoriesFiltered({ name: typedText })
            }
            getSelectedOption={(loadedOptions) => {
              if (!category) return null;
              return (
                loadedOptions.find(
                  (option) => option.id === Number(category)
                ) ?? CategoryProductService.getCategoryById(category)
              );
            }}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>

        <Grid
          item
          lg={5}
          className="mb-10 d-flex justify-content-between align-items-center"
        >
          <ApiResourceSelect
            style={{ width: "100%" }}
            label="Sub categoria"
            noOptionsText="Nenhuma sub categoria encontrada"
            getOptionLabel={(option: any) => option.nameSubCategory}
            value={subCategory}
            onSelect={(option) => setSubCategory(String(option?.id ?? ""))}
            apiSearchHandler={(typedText) =>
              SubCategoryProductService.getSubCategoriesFiltered(
                selectedCategoryProductUuid,
                { name: typedText }
              )
            }
            getSelectedOption={(loadedOptions) => {
              if (!subCategory) return null;
              return (
                loadedOptions.find(
                  (option) => option.id === Number(subCategory)
                ) ?? SubCategoryProductService.getSubCategoryById(subCategory)
              );
            }}
            disabled={user.isAccountant == "y" ? true : false}
          />
        </Grid>
      </Grid>
    </div>
  );
}
