import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  BodyDataBaseProps,
  HeadDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import useCompany from "../../hooks/company";
import api from "../../services/Api";
import { BsVariant } from "../../types/BsVariant";
import { getDate } from "../../utils/dateTimeHelper";
import { Search } from "../../components/Search";
import useBackendLoad from "../../hooks/backendReload";
import CompanyService, { Filters } from "../../services/CompanyService";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/dateFormat";
import CompanySubscriptionPlanService from "../../services/CompanySubscriptionPlanService";
import { saveAs } from "file-saver";

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "N°" },
  { reference: "createdDate", value: "Data Cadastro" },
  { reference: "name", value: "Nome" },
  { reference: "responsible", value: "Responsável" },
  { reference: "cell", value: "Celular" },
  { reference: "businessCategory", value: "Segmento" },
  { reference: "subscriptionPlan", value: "Plano" },
  { reference: "status", value: "Status", notSortable: true },
  { reference: "expirationDate", value: "Expira Em", notSortable: true },
];

export function ListCompany() {
  const { user } = useSelector((state: any) => state.auth);

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  const [companies, setCompanies] = useState<any[]>([]);
  const [countTotalCompanies, setCountTotalCompanies] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const { getStatusText, getStatusValue } = useCompany({
    appendSubscriptionPlan: true,
  });
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

  const [blockMsg, setBlockMsg] = useState("");
  const [companyToBlockId, setCompanyToBlockId] = useState("");

  const [showBlockModal, setShowBlockModal] = useState(false);

  const loadData = useCallback(async (dataParams: LoadDataParams) => {
    const {
      rows,
      count,
    } = await CompanyService.getCompaniesWithPagination(
      dataParams,
      filtersRef.current,
      { withSubscriptionPlan: true }
    );

    setCompanies(rows);
    setCountTotalCompanies(count);
  }, []);

  const businessCategoryMap: Record<string, string> = {
    FOOD_AND_BEVERAGES: "Alimentos e Bebidas",
    AUTO_PARTS: "Autopeças",
    BEAUTY_AND_AESTHETICS: "Beleza e Estética",
    ACCOUNTING: "Contabilidade",
    DISTRIBUTORS: "Distribuidoras",
    CONSTRUCTION_MATERIAL: "Material de Construção",
    MARKET: "Mercado",
    FASHION_AND_CLOTHING: "Moda e Vestuário",
    WORKSHOP: "Oficina",
    OPTICS: "Ótica",
    DEFAULT: "Outros",
    PET_SHOP: "Pet Shop",
    SERVICE_PROVIDERS: "Prestadores de Serviços",
    OFFICE_SERVICES: "Serviços de Escritórios",
    TECHNOLOGY_AND_IT: "Tecnologia e Informática",
  };

  const translateBusinessCategory = (category: string): string => {
    return businessCategoryMap[category] || "Categoria Desconhecida";
  };

  // useEffect(() => {
  //     handleClickSearch();
  // }, [companies]);

  useEffect(() => {
    (async () => {
      const list: BodyDataBaseProps[][] = [];
      const aux = companies;

      for (const item of aux) {
        const companyPaymentRequired = await CompanySubscriptionPlanService.planPaymentIsRequired(
          item.id
        );
        const isCompanyBlockedBecauseOfPayment =
          companyPaymentRequired.isRequired;
        const status = isCompanyBlockedBecauseOfPayment
          ? "PAGAMENTO PENDENTE"
          : String(getStatusText(item));

        const data: BodyDataBaseProps[] = [
          { for: "id", value: String(item.id), id: true },
          {
            for: "createdDate",
            value: getDate({ initialDate: new Date(item.createdDate) }).dateStr,
          },
          { for: "name", value: item.name },
          { for: "responsible", value: item.responsible ?? "" },
          {
            for: "cell",
            value: item.cell ? (
              <a
                href={`https://wa.me/55${String(item.cell).replace(/\D/g, "")}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {String(item.cell)}
              </a>
            ) : (
              ""
            ),
          },
          {
            for: "businessCategory",
            value: item.businessCategory
              ? translateBusinessCategory(item.businessCategory)
              : "",
          },
          {
            for: "subscriptionPlan",
            value: item.plan ? item.plan.subscriptionPlan.name : "",
          },
          { for: "status", value: status },
          {
            for: "expirationDate",
            value:
              item.plan && item.plan.expirationDate
                ? formatDate(item.plan.expirationDate)
                : "",
          },
          {
            for: "actionButton",
            value: getStatusValue(item) === 0 ? "n" : "y",
            hidden: true,
          },
          {
            for: "actionButtonAlt",
            value: getStatusValue(item) === 0 ? "y" : "n",
            hidden: true,
          },
        ];

        list.push(data);
      }

      setBodyData(list);
    })();
  }, [companies]);

  const disableButtonCondition = useCallback(
    (id: string) => {
      const row = companies.find((obj) => String(obj.id) === id);
      return getStatusValue(row) !== 0;
    },
    [companies]
  );
  const enableButtonCondition = useCallback(
    (id: string) => {
      const row = companies.find((obj) => String(obj.id) === id);
      return getStatusValue(row) === 0;
    },
    [companies]
  );

  const handleClickDelete = useCallback(
    async (id: string) => {
      await api.delete(`companies/${id}`);
      const aux = companies.filter((company) => String(company.id) !== id);

      setCompanies([...aux]);
    },
    [companies]
  );

  const handleClickEdit = useCallback((id: string) => {
    pushHistory(`${pathname}/${id}`);
  }, []);

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, []);

  const exportCompanies = useCallback(() => {
    if (companies.length === 0) return;

    // Pega as chaves do primeiro objeto para usar como cabeçalho
    const keys = Object.keys(companies[0]);
    const header = keys.join(";");

    // Para cada objeto da companies, gera uma linha do CSV
    const rows = companies.map((company) =>
      keys
        .map((key) => {
          const value = company[key];
          // Coloca aspas no campo que conter ; ou quebra de linha, se necessário
          if (
            typeof value === "string" &&
            (value.includes(";") || value.includes("\n"))
          ) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(";")
    );

    const csvContent = [header, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "companies.csv");
  }, [companies]);

  const handleClickDisable = useCallback((id: string) => {
    setShowBlockModal(true);
    setCompanyToBlockId(id);
    setBlockMsg("");
  }, []);

  const handleClickDisableConfirm = useCallback(async () => {
    await api.put(`companies/${companyToBlockId}`, {
      status: 0,
      blockMessage: blockMsg,
      noApi: true,
    });

    setShowBlockModal(false);

    const aux = companies;
    const auxIndex = companies.findIndex(
      (company) => company.id === Number(companyToBlockId)
    );

    aux[auxIndex].status = 0;
    aux[auxIndex].blockMessage = blockMsg;

    setCompanies([...aux]);
  }, [companyToBlockId, blockMsg, companies]);

  const handleClickEnableConfirm = useCallback(
    async (id: string) => {
      await api.put(`companies/${id}`, {
        status: 1,
        blockMessage: "",
      });

      const aux = companies;
      const auxIndex = companies.findIndex(
        (company) => company.id === Number(id)
      );

      aux[auxIndex].status = 1;
      aux[auxIndex].blockMessage = "";

      setCompanies([...aux]);
    },
    [companies]
  );

  const handleClickSearch = useCallback(() => {
    filtersRef.current = { searchQuery };

    reloadData();
  }, [companies, searchQuery]);

  return (
    <>
      <Modal
        show={showBlockModal}
        onHide={() => setShowBlockModal(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Desativar Empresa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Mensagem de bloqueio</h3>
          <div className="form-group">
            <TextField
              id="outlined-select-currency"
              size="small"
              label="Mensagem"
              margin="normal"
              variant="outlined"
              value={blockMsg}
              onChange={(e) => setBlockMsg(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowBlockModal(!showBlockModal)}
          >
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickDisableConfirm}>
            Desativar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="card card-body pt-4">
        <div className="row d-flex align-items-center">
          <div className="col-lg-9 mt-3">
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => handleClickAdd()}
            >
              Adicionar Empresa
            </Button>
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => exportCompanies()}
            >
              Exportar Empresas
              <i className="flaticon2-download-1 ml-2"></i>
            </Button>
          </div>
          <div className="col-lg-3 mt-3">
            <Search
              query={searchQuery}
              setQuery={setSearchQuery}
              onClickSearch={handleClickSearch}
            />
          </div>
        </div>

        <div className="mt-3">
          <ListWithModalChangeSituation
            headData={headData}
            bodyData={bodyData}
            onEdit={handleClickEdit}
            onDelete={handleClickDelete}
            customButtons={
              user.isAccountant == "n"
                ? [
                    {
                      class: "btn-light-danger",
                      content: "OFF",
                      variant: BsVariant.INFO,
                      popup: "Desativar empresa",
                      onClick: handleClickDisable,
                      showCondition: disableButtonCondition,
                    },
                    {
                      class: "btn-light-success",
                      content: "ON",
                      variant: BsVariant.INFO,
                      popup: "Ativar empresa",
                      onClick: handleClickEnableConfirm,
                      showCondition: enableButtonCondition,
                    },
                  ]
                : []
            }
            sortable={true}
            loadData={loadData}
            totalCount={countTotalCompanies}
            triggerLoad={triggerLoad}
            setTriggerLoad={setTriggerLoad}
          />
        </div>
      </div>
    </>
  );
}
