import React, { useState } from "react";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";

import { usePurchaseOrder, Product } from "./context";
import { NumericFormat } from "../../components/NumericFormat";
import { formatToFloat } from "../../utils/formatCurrency";

import "../../style.css";
import ProductSelect from "../../components/ProductSelect";
import { useSelector } from "react-redux";
import { Product as SelectableProduct } from "../../services/ProductService";

interface ListProductsProps {
  index: number;
  product: Product;
  status?: string;
  isEdit?: boolean;
  entityId?: number | string;
  onClickAddProduct?: (
    typedText: string,
    index: number,
    product: Product
  ) => void;
}

export const ListProducts: React.FC<ListProductsProps> = ({
  index,
  product,
  status,
  isEdit,
  entityId,
  onClickAddProduct,
}) => {
  const { user } = useSelector((state: any) => state.auth);

  const { dispatchProducts } = usePurchaseOrder();
  const [contentError, setContentError] = useState(false);

  const handleChangeProduct = (value: SelectableProduct) => {
    const productObj = typeof value === "string" ? null : value;
    const productName = typeof value === "string" ? value : "";

    dispatchProducts({
      type: "CHANGE",
      payload: {
        index,
        product: {
          isLinked: !!productObj,
          name: productObj?.name ?? productName,
          amount: 1,
          INSTANCE: productObj ?? ({} as SelectableProduct),
          total: productObj?.saleValue ?? 0,
          unitaryValue: productObj?.saleValue ?? 0,
          cfop: "0",
          icms: "0",
          ipi: "0",
          ncm: "0",
        },
      },
    });
  };

  const handleChangeValues = (amount: number, unitaryValue: any) => {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index,
        product: {
          ...product,
          amount: amount,
          unitaryValue: formatToFloat(unitaryValue),
          total: amount * formatToFloat(unitaryValue),
        },
      },
    });
  };

  return (
    <div className="row col-lg-12 p-0 pl-7 pr-7 newProductWrapper">
      <div className="col-lg-4 d-flex align-items-center p-1 ">
        <div className="mr-2 d-flex justify-content-center align-items-center bg-secondary p-3 mt-2">
          <label className="text-white m-0">{index + 1}.</label>
        </div>

        <ProductSelect
          label="Produto"
          isEdit={isEdit}
          selectedProduct={product}
          onChangeProduct={handleChangeProduct}
          entityType="purchase"
          entityId={entityId}
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
          hasError={product.hasError}
          allowIncomplete={true}
          type="product"
          onClickAddProduct={
            onClickAddProduct
              ? (typedText) => onClickAddProduct(typedText, index, product)
              : undefined
          }
        />
      </div>

      <div className="col p-1">
        <TextField
          size="small"
          label="Qtde."
          margin="normal"
          variant="outlined"
          name="amount"
          type="number"
          value={product.amount}
          onChange={(evt) =>
            handleChangeValues(Number(evt.target.value), product.unitaryValue)
          }
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          className="inputSmaller"
          margin="normal"
          customInput={TextField}
          variant="outlined"
          withPrefix={false}
          label="IPI %"
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
          value={product.ipi}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  ipi: evt.target.value,
                },
              },
            })
          }
          onBlur={(e) => {
            var converted = e.target.value ? formatToFloat(e.target.value) : 0;

            console.log(converted);
            if (converted > 100) {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    ipi: "100",
                  },
                },
              });
            } else {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    ipi: converted ? String(converted) : "0",
                  },
                },
              });
            }
          }}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          className="inputSmaller"
          margin="normal"
          customInput={TextField}
          variant="outlined"
          withPrefix={false}
          label="ICMS %"
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
          value={product.icms}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  icms: evt.target.value,
                },
              },
            })
          }
          onBlur={(e) => {
            var converted = e.target.value ? formatToFloat(e.target.value) : 0;

            if (converted > 100) {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    icms: "100",
                  },
                },
              });
            } else {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    icms: converted ? String(converted) : "0",
                  },
                },
              });
            }
          }}
        />
      </div>

      <div className="col-lg-2 p-1">
        <NumericFormat
          className="inputSmaller"
          label="Valor Unit."
          value={product.unitaryValue}
          withPrefix={false}
          onChange={(evt) =>
            handleChangeValues(Number(product.amount), evt.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          className="inputSmaller"
          margin="normal"
          customInput={TextField}
          variant="outlined"
          withPrefix={false}
          format="####"
          label="CFOP"
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
          value={product.cfop}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  cfop: evt.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          className="inputSmaller"
          margin="normal"
          customInput={TextField}
          variant="outlined"
          withPrefix={false}
          label="NCM"
          format="########"
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
          value={product.ncm}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  ncm: evt.target.value,
                },
              },
            })
          }
        />
      </div>

      <div className="col-lg-2 p-1">
        <NumericFormat
          className="inputSmaller"
          label="Valor Total"
          disabled
          value={product.total}
          withPrefix={false}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </div>

      {index > 0 && user.isAccountant == "n" && (
        <button
          type="button"
          className="btn btn-link"
          onClick={() =>
            dispatchProducts({
              type: "DELETE",
              payload: {
                index,
              },
            })
          }
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        >
          <i className="flaticon-delete"></i>
        </button>
      )}
    </div>
  );
};
